import React, { useState, useEffect } from "react";
import { fadingPixelThreshold } from "../util";

interface HeaderProps {
    introRef: React.RefObject<HTMLDivElement>;
    aboutRef: React.RefObject<HTMLDivElement>;
    projectsRef: React.RefObject<HTMLDivElement>;
}

function Header({ introRef, aboutRef, projectsRef }: HeaderProps) {
    const [headerOpacity, setHeaderOpacity] = useState(0);
    const [headerVisible, setHeaderVisible] = useState("invisible");
    const headerItems = ["Intro", "About Me", "Projects"];

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const newOpacity = Math.max(0, (scrollY - fadingPixelThreshold) / fadingPixelThreshold);
        setHeaderOpacity(newOpacity);

        const newVisible = scrollY > fadingPixelThreshold ? "visible" : "invisible";
        setHeaderVisible(newVisible);
    };

    const handleClick = (ref: string) => {
        if (ref === "intro") {
            introRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (ref === "about me") {
            aboutRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (ref === "projects") {
            projectsRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={`fixed right-0 top-1/3 mr-10 rounded-3xl bg-blue-300 shadow-2xl ${headerVisible}`} style={{ opacity: headerOpacity }}>
            <div className="flex flex-col justify-center items-center space-y-3 p-6 text-white text-2xl font-bold retro-game-font tracking-widest">
                {headerItems.map((item, index) => {
                    return (
                        <div key={index} onClick={() => handleClick(item.toLowerCase())} className="cursor-pointer mx-4 hover:underline">
                            {item}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Header;
