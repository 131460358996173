import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import { fadingPixelThreshold } from "./util";

function App() {
    const introRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);
    const projectsRef = useRef<HTMLDivElement>(null);
    const [titleOpacity, setTitleOpacity] = useState(1);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const newOpacity = Math.max((fadingPixelThreshold - scrollY) / fadingPixelThreshold, 0);
        setTitleOpacity(newOpacity);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div>
            <Header introRef={introRef} aboutRef={aboutRef} projectsRef={projectsRef} />
            <div className="flex flex-col items-center text-justify text-white text-5xl">
                <div ref={introRef} id="intro" />
                <div className="h-screen"></div>
                <div className="fixed flex flex-col justify-center items-center h-screen" style={{ opacity: titleOpacity }}>
                    <p className="text-xl">Hello, I'm</p>
                    <div className="my-4 p-5 rounded-xl three-colour-gradient">
                        <h5 className="text-[6rem] p-1 font-bold retro-game-font rounded-lg tracking-widest">Colton Gowans</h5>
                    </div>
                    <p className="mb-16 mt-4 text-xl ">Computer Science student at the University of Calgary</p>
                </div>

                <div className="my-10"></div>
                <div ref={aboutRef} id="about" />
                <div className="flex justify-center w-1/2 mt-10 m-6">
                    <p>About Me</p>
                </div>

                <div ref={projectsRef} id="projects"></div>
                <div className="w-1/3 m-6">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis nisi obcaecati perspiciatis dolorum accusamus, cumque ipsa eos aliquid illum ut dolor eveniet id modi at, nesciunt expedita praesentium sunt harum!</p>
                </div>

                {/* <div className="h-96">Your about section content goes here</div> */}
            </div>
        </div>
    );
}

export default App;
